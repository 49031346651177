import Page from '../../wraps/page/page.wrap';
import { Link } from 'react-router-dom';

export const HomePage = () => {
  return (
    <Page>
      HOME
      <Link to={'/onstreet/clients'}>CLIQUEZ MOI</Link>
    </Page>
  );
};
