export const search = (context, { list, paging }) => {
  return {
    list,
    paging,
  };
};

export const read = (context, element) => {
  return element;
};
