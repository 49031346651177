import { useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';

interface Props {
  data?: any;
  config: {
    options: any;
  };
  children: any;
}

const Card3D: React.FC<Props> = ({ config, children }) => {
  const { options } = config;
  const tilt: any = useRef(null);

  const init = () => {
    if (!tilt.current?.vanillaTilt) {
      VanillaTilt.init(tilt.current, options);
    }
  };

  return (
    <div onMouseOver={() => init()} ref={tilt}>
      {children}
    </div>
  );
};

export default Card3D;
