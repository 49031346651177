import './description.component.scss';
import { Descriptions as DescriptionsAnt } from 'antd';

interface DescriptionItem {
  key: string;
  label: string;
  children: any;
}

interface Props {
  data: {
    items: DescriptionItem[];
  };
  config?: {
    layout?: 'vertical' | 'horizontal';
  };
}

const Description: React.FC<Props> = ({ data, config }) => {
  return (
    <DescriptionsAnt
      className="description component"
      items={data.items}
      layout={config?.layout ?? 'horizontal'}
      size="small"
    ></DescriptionsAnt>
  );
};

export default Description;
