import './datepicker.component.scss';
import { DatePicker as DatePickerAnt } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

interface Props {
  handleEvent: (type: string, value: any) => any;
  config: {
    selected: Date;
    mode: string;
  };
}

const DatePicker: React.FC<Props> = ({
  handleEvent,
  config: { selected, mode },
}) => {
  switch (mode) {
    case 'single':
      return (
        <DatePickerAnt
          defaultValue={dayjs(selected)}
          className="datepicker"
          onClick={(date) => handleEvent('date', date)}
        />
      );
    case 'range':
      return (
        <DatePickerAnt.RangePicker
          // defaultValue={dayjs(selected)}
          className="datepicker"
          onClick={(date) => handleEvent('date', date)}
        />
      );
  }

  return <span>Choisit un mode</span>;
};

export default DatePicker;
