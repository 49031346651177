import { gql } from '@apollo/client';

export const parkingRightGql = gql`
  query parkingRight($parkingRightId: String!) {
    parkingRight(parkingRightId: $parkingRightId) {
      _id
      consumer
      state
      segment
      startDate
      endDate
      durationDetails {
        type
        duration
      }
      amount
      currency
      amountDetails {
        type
        amount
      }
      vehicle {
        lpn
      }
      ups
      siteId
      terminalId
      deviceId
      accountId
      bookingId
      allocatedSpace
      authorizeTransactionId
      captureTransactionId
      paymentsTransactions
      paymentsRecords
      receipts
      error
      externalId
      internalId
    }
  }
`;
