import React from 'react';
import { ConfigProvider, theme } from 'antd';
import ReactDOM from 'react-dom/client';
import { colorCSS } from './functions/color.function';
import reportWebVitals from './reportWebVitals';

import './sass/base/base.scss';
import './sass/base/reset.scss';
import { CustomRouter } from './CustomRouter';
import { gsap } from 'gsap';
// import './app.less';
gsap.config({
  autoSleep: 60,
  force3D: false,
  nullTargetWarn: true,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const color = {
  primary: colorCSS('--color-primary'),
  secondary: colorCSS('--color-secondary'),
  action: colorCSS('--color-action'),
};

console.log(theme.darkAlgorithm);

root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: color.action,
          colorLink: color.action,
          colorInfo: color.action,
          colorBgContainer: 'transparent',
          colorBgElevated: 'rgb(0, 0, 0, 0.96)',
          lineHeight: 1,
        },
        components: {
          Pagination: {
            itemActiveBg: 'transparent',
            itemBg: 'transparent',
            itemInputBg: 'transparent',
            itemLinkBg: 'transparent',
          },
        },
      }}
    >
      <CustomRouter />
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
