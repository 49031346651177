import './row.wrap.scss';

interface Props {
  children: any;
  config?: {
    yMax: number | string;
  };
}

const Row: React.FC<Props> = ({ children, config }) => {
  return (
    <div style={{ maxHeight: config?.yMax ?? 'initial' }} className="row">
      {children}
    </div>
  );
};

export default Row;
