import './tab.component.scss';
import { Tabs, Tabs as TabsAnt } from 'antd';
import { gsap } from 'gsap';
import React, { useEffect, useRef } from 'react';

interface Item {
  key: string;
  label: string;
  children: any;
}

interface Props {
  handleEvent: (type: string, value: any) => any;
  data: {
    items: Item[];
    selected: string;
  };
}

const Tab: React.FC<Props> = ({ handleEvent, data: { items, selected } }) => {
  return (
    <TabsAnt
      className="tab"
      defaultActiveKey={selected}
      items={items}
      onChange={(option) => handleEvent('option', option)}
    />
  );
};

export default Tab;
