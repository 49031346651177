import { PagePropsType } from '../../../types/pages';
import Page from '../../../wraps/page/page.wrap';

export const TransactionsOverview: React.FC<PagePropsType> = ({ machine }) => {
  return (
    <Page>
      <span>Overview de transactions</span>
    </Page>
  );
};
