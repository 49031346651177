import { ClockCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import DatePicker from '../../../components/datepicker/datepicker.component';
import Diagramme from '../../../components/diagramme/diagramme.component';
import Graph from '../../../components/graph/graph.component';
import Space from '../../../components/space/space.component';
import Tab from '../../../components/tab/tab.component';
import Table from '../../../components/table/table.component';
import Timeline from '../../../components/timeline/timeline.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Row from '../../../wraps/row/row.wrap';
import Widget from '../../../wraps/widget/widget.wrap';

export const OperatorsDetails: React.FC<PagePropsType> = ({ machine }) => {
  const [rubrique, setRubrique] = useState('Over');

  return (
    <Page>
      <Col>
        {rubrique === 'Over' && (
          <Widget config={{ title: 'Operator' }}>
            <Timeline
              data={{
                items: [
                  {
                    label: '2015-09-01',
                    children: 'Collecte CB',
                    color: 'green',
                  },
                  {
                    label: '2015-09-02 09:12:11',
                    children: 'Collecte de pièces',
                    color: 'green',
                  },
                  {
                    label: '2015-09-03 11:12:11',
                    children: 'Collecte de pièces',
                    color: 'green',
                  },
                  {
                    label: '2015-09-05 09: 12:11',
                    children: "Alarm d'energie",
                    color: 'red',
                  },
                  {
                    label: '2015-09-07 09:12:11',
                    children: "Passage d'un opérateur",
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Collecte CB',
                    color: 'green',
                  },
                  {
                    label: '2015-09-02 09:12:11',
                    children: 'Collecte de pièces',
                    color: 'green',
                  },
                  {
                    label: '2015-09-03 11:12:11',
                    children: 'Collecte de pièces',
                    color: 'green',
                  },
                  {
                    label: '2015-09-05 09: 12:11',
                    children: "Alarm d'energie",
                    color: 'red',
                  },
                  {
                    label: '2015-09-07 09:12:11',
                    children: "Passage d'un opérateur",
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                  {
                    label: '2015-09-01',
                    children: 'Create a services',
                    dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                  },
                  {
                    color: 'red',
                    label: '2015-09-01 09:12:11',
                    children: 'Solve initial network problems',
                  },
                  {
                    children: 'Technical testing',
                  },
                  {
                    color: 'orange',
                    label: '2015-09-01 09:12:11',
                    children: 'Network problems being solved',
                  },
                ],
              }}
              config={{
                mode: 'left',
              }}
            />
          </Widget>
        )}

        {rubrique === 'Finance' && (
          <Row>
            <Col config={{ flex: 2 }}>
              <Row>
                <Widget config={{ title: 'Collecte de pièces' }}>
                  <Table
                    handleEvent={(type: string, value: any) => {}}
                    data={{
                      list: [
                        {
                          date: '11/05/2023 11:31:21',
                          montant: '1.2 CHF',
                          plaque: 'GE777BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 80,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '1',
                        },
                        {
                          date: '11/05/2023 11:31:22',
                          montant: '1.2 EUR',
                          plaque: 'CD437BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '2',
                        },
                        {
                          date: '11/05/2023 11:31:21',
                          montant: '1.2 CHF',
                          plaque: 'GE777BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '3',
                        },
                        {
                          date: '11/05/2023 11:31:22',
                          montant: '1.2 EUR',
                          plaque: 'CD437BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '4',
                        },
                        {
                          date: '11/05/2023 11:31:21',
                          montant: '1.2 CHF',
                          plaque: 'GE777BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '5',
                        },
                        {
                          date: '11/05/2023 11:31:22',
                          montant: '1.2 EUR',
                          plaque: 'CD437BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '6',
                        },
                        {
                          date: '11/05/2023 11:31:21',
                          montant: '1.2 CHF',
                          plaque: 'GE777BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '7',
                        },
                        {
                          date: '11/05/2023 11:31:22',
                          montant: '1.2 EUR',
                          plaque: 'CD437BZ',
                          expandable: (
                            <Diagramme
                              data={{
                                items: [
                                  {
                                    type: '5 CHF',
                                    value: 10,
                                    date: '2023',
                                  },
                                  {
                                    type: '2 CHF',
                                    value: 250,
                                    date: '2023',
                                  },
                                  {
                                    type: '1 CHF',
                                    value: 180,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.5 CHF',
                                    value: 150,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.2 CHF',
                                    value: 100,
                                    date: '2023',
                                  },
                                  {
                                    type: '0.1 CHF',
                                    value: 910,
                                    date: '2023',
                                  },
                                ],
                              }}
                              config={{
                                type: {
                                  field: 'date',
                                  alias: 'test1',
                                },
                                value: {
                                  field: 'value',
                                  alias: 'test2',
                                },
                                yMax: 100,
                                category: 'type',
                                mode: 'bar.basic.horizontal',
                              }}
                            />
                          ),
                          _id: '8',
                        },
                      ],
                      paging: { limit: 1, current: 1, count: 1 },
                    }}
                    config={{
                      yMax: 350,
                      columns: [
                        {
                          title: 'Date',
                          key: 'date',
                        },
                        {
                          title: 'Montant',
                          key: 'montant',
                        },
                        {
                          title: 'Plaque',
                          key: 'plaque',
                        },
                      ],
                    }}
                  />
                </Widget>
              </Row>
              <Row>
                <Widget config={{ title: 'Etat du coffre' }}>
                  <Col>
                    <DatePicker
                      handleEvent={(type, value) => {
                        console.log({ type, value });
                      }}
                      config={{ selected: new Date(), mode: 'range' }}
                    />
                    <Space />
                    <Tab
                      handleEvent={(type, value) => {
                        switch (type) {
                          case 'option':
                        }
                      }}
                      data={{
                        items: [
                          {
                            key: 'euro',
                            label: 'Euro',
                            children: (
                              <Col>
                                <Graph
                                  data={{
                                    items: [
                                      {
                                        type: '2011',
                                        value: 10,
                                      },
                                      {
                                        type: '2012',
                                        value: 250,
                                      },
                                      {
                                        type: '2013',
                                        value: 180,
                                      },
                                      {
                                        type: '2014',
                                        value: 150,
                                      },
                                      {
                                        type: '2015',
                                        value: 100,
                                      },
                                      {
                                        type: '2016',
                                        value: 300,
                                      },
                                    ],
                                  }}
                                  config={{
                                    type: {
                                      field: 'type',
                                      alias: 'test1',
                                    },
                                    value: {
                                      field: 'value',
                                      alias: 'test2',
                                    },
                                    yMax: 200,
                                    mode: 'line.basic',
                                  }}
                                />
                              </Col>
                            ),
                          },
                          {
                            key: 'chf',
                            label: 'Franc Suisse',
                            children: (
                              <Col>
                                <Graph
                                  data={{
                                    items: [
                                      {
                                        type: '2011',
                                        value: 10,
                                      },
                                      {
                                        type: '2012',
                                        value: 250,
                                      },
                                      {
                                        type: '2013',
                                        value: 180,
                                      },
                                      {
                                        type: '2014',
                                        value: 150,
                                      },
                                      {
                                        type: '2015',
                                        value: 100,
                                      },
                                      {
                                        type: '2016',
                                        value: 910,
                                      },
                                    ],
                                  }}
                                  config={{
                                    type: {
                                      field: 'type',
                                      alias: 'test1',
                                    },
                                    value: {
                                      field: 'value',
                                      alias: 'test2',
                                    },
                                    yMax: 200,
                                    mode: 'line.basic',
                                  }}
                                />
                              </Col>
                            ),
                          },
                        ],
                        selected: 'chf',
                      }}
                    />
                  </Col>
                </Widget>
              </Row>
            </Col>
          </Row>
        )}
      </Col>
    </Page>
  );
};
