import { assign, createMachine, interpret } from 'xstate';
import { requestGQL } from '../../functions/request.function';
import { clientGql } from './gql/client.gql';
import { clientsGql } from './gql/clients.gql';
import {
  read as readRender,
  search as searchRender,
} from './projection/clients.render';

const initialContext = {
  clients: undefined,
  client: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'clients',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          SEARCH_CLIENTS: {
            target: 'search',
          },
          CLIENT_SELECTED: {
            target: 'read',
          },
        },
      },

      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              clients: (context, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              clients: () => undefined,
            }),
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({
              client: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              client: () => undefined,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    services: {
      search: (context, { params }) =>
        requestGQL({
          params,
          gql: clientsGql,
          render: (res) => searchRender(context, res),
        }),
      read: (context, { params }) =>
        requestGQL({
          params,
          gql: clientGql,
          render: (res) => readRender(context, res),
        }),
    },
    guards: {
      ready: () => true,
    },
  },
);

export const clientsService = interpret(machine).start();
