import './field.component.scss';
import { Input as InputAnt } from 'antd';
import { v4 as uuidv4 } from 'uuid';
interface Props {
  config: {
    label?: {
      text?: string;
    };
    input?: {
      placeholder?: string;
      type?: string;
    };
  };
  handleEvent: (type: string, value: any) => any;
}

const Field: React.FC<Props> = ({ handleEvent, config }) => {
  const uuid = uuidv4();

  return (
    <div className="field">
      {config?.label && (
        <label className="field__label" htmlFor={`${uuid}`}>
          {config?.label?.text}
        </label>
      )}
      <InputAnt
        className="field__input"
        placeholder={config?.input?.placeholder}
        type={config?.input?.type ? config?.input.type : 'text'}
        id={`${uuid}`}
        onChange={(e) => {
          handleEvent('input', e.target.value);
        }}
      />
    </div>
  );
};

export default Field;
