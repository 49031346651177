import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  TypedDocumentNode,
  createHttpLink,
  QueryOptions,
  MutationOptions,
} from '@apollo/client/core';

export interface IDomain {
  query<T>(
    query: TypedDocumentNode,
    options: QueryOptions,
  ): Promise<{ [key: string]: any } | null>;
  mutation<T>(
    mutation: TypedDocumentNode,
    options: MutationOptions,
    projection: () => T,
  ): Promise<T | null>;
}

export class Domain implements IDomain {
  constructor(protected url: string) {
    const urlLink = createHttpLink({ uri: url });

    // const authLink = setContext(() => {
    // const token = config.serverToken;
    // return {
    //     headers: {
    //     Authorization: token,
    //     },
    // };
    // });

    // this.client = new ApolloClient({
    //   link: urlLink,
    //   cache: new InMemoryCache(),
    // });

    console.log('Domain built');
  }
  // private client: ApolloClient<NormalizedCacheObject>;
  private client = new ApolloClient<NormalizedCacheObject>({
    uri: this.url,
    cache: new InMemoryCache(),
  });

  async query<T>(
    query: TypedDocumentNode,
    options: QueryOptions,
    // projection: (data: any) => T,
  ): Promise<{ [key: string]: any } | null> {
    const { data } = await this.client.query({ ...options, query });
    return data;
  }

  async mutation<T>(
    mutation: TypedDocumentNode,
    options: MutationOptions,
    // projection: (data: any) => T,
  ): Promise<T | null> {
    // return this.client
    //   .query({ query: mutation })
    //   .then((data) => projection(data));
    const { data } = await this.client.mutate({ ...options, mutation });
    return data;
  }
}
