import { gql } from '@apollo/client';

export const mails = gql`
  query mails($page: Int, $limit: Int, $order: String) {
    mails(page: $page, limit: $limit, order: $order) {
      list {
        _id
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
