import { gql } from '@apollo/client';

export const parkingRightsGql = gql`
  query parkingRights($limit: Int!, $page: Int!, $order: String) {
    parkingRights(limit: $limit, page: $page, order: $order) {
      list {
        _id
        consumer
        state
        startDate
        endDate
        amount
        currency
        vehicle {
          lpn
        }
        ups
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
