import { gql } from '@apollo/client';

export const sessionsGql = gql`
  query sessions($limit: Int!, $page: Int!, $order: String) {
    sessions(limit: $limit, page: $page, order: $order) {
      list {
        _id
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
