import { useSelector } from '@xstate/react';
import Table from '../../../components/table/table.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Widget from '../../../wraps/widget/widget.wrap';
import { useEffect } from 'react';
import { useNavigate, dispatchOnIdle } from '@gimlite/router';

export const ContractsSearch: React.FC<PagePropsType> = ({ machine }) => {
  const search = useSelector(machine, ({ context }: any) => context.search);

  const navigate = useNavigate();

  const searchFn = ({ limit, page }: { limit: number; page: number }) => {
    dispatchOnIdle(machine, 'SEARCH_CONTRACTS', {
      params: {
        limit: limit,
        page: page,
      },
    });
  };

  useEffect(() => {
    searchFn({ limit: 20, page: 1 });
  }, []);

  return (
    <Page>
      <Col>
        <Widget config={{ title: 'Liste des contrats' }}>
          <Table
            handleEvent={(type: string, value: any) => {
              switch (type) {
                case 'paging':
                  searchFn(value);
                  break;
                case 'read':
                  console.log({ value });

                  break;
              }
            }}
            data={search}
            config={{
              columns: [
                {
                  title: 'Name',
                  key: 'slug',
                },
                {
                  title: 'Siret',
                  key: 'siret',
                },
              ],
            }}
          />
        </Widget>
      </Col>
    </Page>
  );
};
