import { dispatchOnIdle } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Widget from '../../../wraps/widget/widget.wrap';
export const ParkingRightsDetails: React.FC<PagePropsType> = ({ machine }) => {
  const { parkingRightId } = useParams();

  useEffect(() => {
    if (parkingRightId) {
      dispatchOnIdle(machine, 'PARKING_RIGHT_SELECTED', { parkingRightId });
    } else {
      dispatchOnIdle(machine, 'PARKING_RIGHT_SELECTED', {
        parkingRightId: '64e4dbd10594623f7a09d233',
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parkingRight = useSelector(
    machine,
    ({ context }: any) => context.parkingRight,
  );

  const [rubrique] = useState('Finance');

  return (
    <Page>
      <Col>
        <Widget config={{ title: 'Operator' }}>
          <ul>
            <li>id: {parkingRight._id}</li>
            <li>amount: {parkingRight.amount}</li>
            <li>currency: {parkingRight.currency}</li>
            <li>consumer: {parkingRight.consumer}</li>
            <li>startDate: {parkingRight.startDate}</li>
            <li>endDate: {parkingRight.endDate}</li>
            <li>state: {parkingRight.state}</li>
            <li>terminalId: {parkingRight.terminalId}</li>
            <li>ups: {parkingRight.ups}</li>
            <li>segment: {parkingRight.segment}</li>
            <li>siteId: {parkingRight.siteId}</li>
            <li>vehicule plate: {parkingRight.vehicle.lpn}</li>
            {parkingRight?.paymentsRecords?.map((e: string) => (
              <li>records: {e}</li>
            ))}
          </ul>
        </Widget>
      </Col>
    </Page>
  );
};
