export const searchRender = (context, { list, paging }) => {
  return {
    list,
    paging,
  };
};

export const readRender = (context, { list, paging }) => {
  const { _id, ...rest } = list[0];

  return {
    ...rest,
    key: _id,
  };
};
