import { useEffect, useState } from 'react';
import './graph.component.scss';
import { Line as LineAnt } from '@ant-design/plots';
import { colorCSS } from '../../functions/color.function';

const Graph = ({ data: { items }, config }) => {
  const {
    type: { field: fieldType, alias: aliasType },
    value: { field: fieldValue, alias: aliasValue },
    category,
    mode,
  } = config;

  const color = {
    graph1: colorCSS('--color-graph1'),
    graph2: colorCSS('--color-graph2'),
    graph3: colorCSS('--color-graph3'),
    graph4: colorCSS('--color-graph4'),
  };

  let options = {
    style: {
      maxHeight: config?.yMax ?? 'initial',
      height: config?.yMax ?? 'initial',
    },
    className: 'graph',
  };

  options = {
    ...options,
    data: items,
    xField: fieldType,
    yField: fieldValue,
    seriesField: category,
    color: Object.values(color),
  };

  switch (mode) {
    case 'line.basic':
      return <LineAnt {...options} />;
  }

  return <span>Choisit un mode</span>;
};

export default Graph;
