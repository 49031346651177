import { useEffect, useRef } from 'react';
import './widget.wrap.scss';
import { gsap } from 'gsap';

interface Props {
  children: any;
  config?: {
    title?: string;
    position?: 'sticky';
  };
}

const Widget: React.FC<Props> = ({ children, config }) => {
  const widgetWrapper: any = useRef(null);

  return (
    <div
      ref={widgetWrapper}
      style={{
        position: config?.position ?? 'initial',
        top: config?.position ? '0px' : 'initial',
        zIndex: config?.position ? '1000' : 'initial',
      }}
      className="widget"
    >
      {config?.title && <h3 className="widget__title">{config.title}</h3>}
      <div className="widget__contain">{children}</div>
    </div>
  );
};

export default Widget;
