import { dispatch, useNavigate } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import Table from '../../../components/table/table.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Widget from '../../../wraps/widget/widget.wrap';

export const ParkingRightsList: React.FC<PagePropsType> = ({ machine }) => {
  const parkingRights = useSelector(
    machine,
    ({ context }: any) => context.parkingRights,
  );
  const navigate = useNavigate();
  const search = ({ limit, page }: { limit: number; page: number }) => {
    dispatch('SEARCH_PARKING_RIGHTS', {
      params: {
        limit: limit,
        page: page,
      },
    });
  };

  useEffect(() => {
    search({ limit: 15, page: 1 });
  }, []);

  return (
    <Page>
      <Col>
        <Widget config={{ title: 'Liste des tickets' }}>
          <Table
            handleEvent={(type: string, value: any) => {
              switch (type) {
                case 'paging':
                  search(value);
                  break;
                case 'read':
                  navigate(`/onstreet/parking-rights/${value._id}`);
                  break;
              }
            }}
            data={parkingRights}
            config={{
              columns: [
                {
                  title: 'Client',
                  key: 'consumer',
                },
                {
                  title: 'Plaque',
                  key: 'plate',
                },
                {
                  title: 'Montant',
                  key: 'amount',
                },
                {
                  title: 'Date de début',
                  key: 'startDate',
                },
                {
                  title: 'Date de fin',
                  key: 'endDate',
                },
                {
                  title: 'Ups',
                  key: 'ups',
                },
              ],
              focus: true,
            }}
          />
        </Widget>
      </Col>
    </Page>
  );
};
