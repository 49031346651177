import { assign, createMachine, interpret } from 'xstate';

import { requestGQL } from '../../functions/request.function';
import { parkingRightGql } from './gql/parkingRight.gql';
import { parkingRightsGql } from './gql/parkingRights.gql';
import {
  read as readRender,
  search as searchRender,
} from './projection/parkingRights.render';

const initialContext = {
  parkingRights: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'tickets',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          SEARCH_PARKING_RIGHTS: {
            target: 'search',
          },
          PARKING_RIGHT_SELECTED: {
            target: 'read',
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              parkingRights: [],
            }),
          },
          onDone: {
            target: 'idle',
            actions: assign({
              parkingRights: (_, { data }) => data,
            }),
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({
              parkingRight: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              site: () => undefined,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    services: {
      search: (context, { params }) =>
        requestGQL({
          params,
          gql: parkingRightsGql,
          render: (res) => searchRender(context, res),
        }),
      read: (context, { parkingRightId }) =>
        requestGQL({
          params: { parkingRightId },
          gql: parkingRightGql,
          render: (res) => readRender(context, res),
        }),
    },
  },
);

export const parkingRightsService = interpret(machine).start();
