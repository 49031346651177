import { assign, createMachine } from 'xstate';
import { interpret } from 'xstate';

import { requestGQL } from '../../functions/request.function';
import { sms as smsGQL } from './gql/sms.gql';
import { search as searchRender } from './projection/sms.render';

const initialContext = {
  searchMails: undefined,
  search: undefined,
  read: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'sms',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          SEARCH_SMS: {
            target: 'search',
          },
        },
      },

      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              search: (context, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              search: () => undefined,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    services: {
      search: (context, { params }) =>
        requestGQL({
          params,
          gql: smsGQL,
          render: (res) => searchRender(context, res),
        }),
    },
    guards: {
      ready: () => true,
    },
  },
);

export const smsService = interpret(machine).start();
