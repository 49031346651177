import {
  CloudDownloadOutlined,
  ApiFilled,
  AlertFilled,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import Badge from '../../../components/badge/badge.component';
import Description from '../../../components/description/description.component';

export const sites = (context, { list, paging }) => {
  return {
    list,
    paging,
  };
};

// site?.peripherals?.list
// ? [
//     ...site.peripherals.list.map((element: any) => {
//       const { kind, swvers } = element;
//       return {
//         title: kind,
//         description: swvers,
//         icon: <CloudDownloadOutlined />,
//       };
//     }),
//   ]

export const site = (
  context,
  {
    alarmsStatus,
    alarms,
    peripherals,
    modemSessions,
    energies,
    versions,
    collects,
    serviceCardEvents,
    ...rest
  },
) => {
  const status = {
    up: {
      status: 'success',
      title: 'La machine fonctionne !',
      subtitle:
        'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression',
    },
    degraded: {
      status: 'warning',
      title: 'Le machine est dans été un dégradé !',
      subtitle:
        'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression',
    },
    down: {
      status: 'error',
      title: 'Le machine ne fonctionne plus !',
      subtitle:
        'Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression',
    },
  };
  const energiesFormatted = energies.list
    .reduce((acc, element) => {
      const { logDate, u } = element;

      const newEntry = Object.entries(u)
        .filter(([name]) => name !== '__typename')
        .map(([name, value]) => {
          return {
            date: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
            value: value,
            category: name,
          };
        });

      acc = [...acc, ...newEntry];

      return acc;
    }, [])
    .reverse();

  const peripheralsFormatted = peripherals.map(({ kind, swvers }) => {
    return {
      title: kind,
      description: swvers,
      icon: <CloudDownloadOutlined />,
    };
  });

  const alarmsFormatted = alarms.list.map(({ key, ...other }) => {
    let newObject = { ...other };

    if (newObject?.startDate)
      newObject.startDate = DateTime.fromISO(newObject.startDate).toFormat(
        'dd/MM/yy HH:mm:ss',
      );
    if (newObject?.stopDate)
      newObject.stopDate = DateTime.fromISO(newObject.stopDate).toFormat(
        'dd/MM/yy HH:mm:ss',
      );

    return {
      terminalId: key,
      ...newObject,
    };
  });

  const modemSessionsFormatted = modemSessions.list.map((element) => {
    const { net, t } = element;

    return {
      _id: element?._id,
      band: net?.band,
      chan: net?.chan,
      oper: net?.oper,
      opn: net?.opn,
      rat: net?.rat,
      rssi: net?.rssi,
      sinr: net?.sinr,
      conn: t?.conn,
      trx: t?.trx,
    };
  });

  const versionsFormatted = versions.map(({ name, version }) => {
    return {
      title: name,
      description: version,
      icon: <ApiFilled />,
    };
  });

  const alarmsStatusFormatted = Object.entries(alarmsStatus.alarms)
    .filter(([key, value]) => value && !!!status[key.toLowerCase()])
    .reduce((acc, [key, value]) => {
      return [
        ...acc,
        {
          title: key.charAt(0).toUpperCase() + key.slice(1),
          icon: <AlertFilled />,
          badge: <Badge config={{ text: false, type: 'down' }} />,
        },
      ];
    }, []);

  const state = Object.entries(alarmsStatus.alarms)
    .filter(([key, value]) => value && !!status[key.toLowerCase()])
    .reduce((acc, [key, value]) => status[key.toLowerCase()], '');

  const collectsFormatted = collects.list.map(
    ({ logDate, totalAmount, coins, currency = 'CHF', ...rest }) => {
      return {
        ...rest,
        totalAmount: new Intl.NumberFormat(currency, {
          style: 'currency',
          currency: currency,
          maximumSignificantDigits: 2,
        }).format(totalAmount / 100),
        expandable: coins ? (
          <Description
            data={{
              items: Object.entries(coins).map(([key, value]) => {
                return {
                  key,
                  label: key,
                  children: value,
                };
              }),
            }}
          />
        ) : undefined,
        logDate: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
      };
    },
  );
  const serviceCardEventsFormatted = serviceCardEvents.list.map(
    ({ logDate, ...other }) => {
      return {
        ...other,
        logDate: DateTime.fromISO(logDate).toFormat('dd/MM/yy HH:mm:ss'),
      };
    },
  );

  return {
    alarms: { ...alarms, list: alarmsFormatted },
    peripherals: peripheralsFormatted,
    energies: { ...energies, list: energiesFormatted },
    modemSessions: { ...modemSessions, list: modemSessionsFormatted },
    versions: versionsFormatted,
    collects: { ...collects, list: collectsFormatted },
    serviceCardEvents: {
      ...serviceCardEvents,
      list: serviceCardEventsFormatted,
    },
    alarmsStatus: alarmsStatusFormatted,
    state: state,
    ...rest,
  };
};
