import { assign, createMachine, interpret } from 'xstate';

import { requestGQL } from '../../functions/request.function';
import { parkingsGql } from './gql/parkings.gql';
import { parkingsRender } from './projection/parkings.render';

const initialContext = {
  parkings: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'tickets',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          SEARCH_PARKINGS: {
            target: 'search',
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onError: {
            target: 'failure',
            actions: assign({ error: (_, { data }) => data }),
          },
          onDone: {
            target: 'idle',
            actions: assign({
              parkings: (_, { data }) => data,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    services: {
      search: (context, { params }) =>
        requestGQL({
          params,
          gql: parkingsGql,
          render: (res) => parkingsRender(context, res),
        }),
    },
  },
);

export const parkingsService = interpret(machine).start();
