import { assign, createMachine, interpret } from 'xstate';
import { requestGQL } from '../../functions/request.function';
import { sites as sitesGql } from './gql/sites.gql';
import { site as siteGql } from './gql/site.gql';
import {
  site as siteRender,
  sites as sitesRender,
} from './projection/sites.render';

const initialContext = {
  sites: [],
  site: undefined,
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'sites',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: { target: 'idle' },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
          SEARCH_SITES: {
            target: 'search',
          },
          SITE_SELECTED: {
            target: 'read',
          },
        },
      },
      search: {
        invoke: {
          id: 'search',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign({
              sites: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              sites: () => [],
            }),
          },
        },
      },
      read: {
        invoke: {
          id: 'read',
          src: 'read',
          onDone: {
            target: 'idle',
            actions: assign({
              site: (_, { data }) => data,
            }),
          },
          onError: {
            target: 'failure',
            actions: assign({
              error: (_, { data }) => data,
              site: () => undefined,
            }),
          },
        },
      },
      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
  },
  {
    services: {
      search: (context, { params }) =>
        requestGQL({
          params,
          gql: sitesGql,
          render: (res) => sitesRender(context, res),
        }),
      read: (context, { siteId }) =>
        requestGQL({
          params: { siteId },
          gql: siteGql,
          render: (res) => siteRender(context, res),
        }),
    },
  },
);

export const sitesMachine = interpret(machine).start();
