import { PagePropsType } from '../../../types/pages';
import Page from '../../../wraps/page/page.wrap';

export const ParkingRightsOverview: React.FC<PagePropsType> = () => {
  return (
    <Page>
      <p>Tickets</p>
    </Page>
  );
};
