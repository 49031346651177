import { DateTime } from 'luxon';

export const search = (context, { list, paging }) => {
  return {
    list: list.map(
      ({ amount, currency, startDate, endDate, vehicle, ...rest }) => {
        return {
          ...rest,
          startDate: new Date(startDate).toLocaleString(DateTime.DATE_SHORT),
          endDate: new Date(endDate).toLocaleString(DateTime.DATE_SHORT),
          plate: vehicle.lpn,
          // TODO: faire un composant avec ça
          amount: new Intl.NumberFormat(currency, {
            style: 'currency',
            currency: currency,
            maximumSignificantDigits: 2,
          }).format(amount / 100),
        };
      },
    ),
    paging,
  };
};

export const read = (context, element) => {
  return element;
};
