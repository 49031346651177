import {
  FullscreenExitOutlined,
  LoginOutlined,
  SettingOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons';

export const menu = (location: any) => ({
  selected: location.pathname,
  group: [
    {
      key: '/onstreet',
      position: 'top',
      title: 'Onstreet',
      items: [
        {
          key: '/onstreet/clients',
          label: 'Clients',
          icon: <UserOutlined />,
          items: [
            {
              key: '/onstreet/clients',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/onstreet/clients/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/onstreet/sites/search',
              label: 'Sites',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
        {
          key: '/onstreet/parking-rights',
          label: 'Tickets',
          icon: <TagOutlined />,
          items: [
            {
              key: '/onstreet/parking-rights',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/onstreet/parking-rights/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
      ],
    },
    {
      key: '/offstreet',
      position: 'top',
      title: 'Offstreet',
      items: [
        {
          key: '/offstreet/operators',
          label: 'Opérateurs',
          icon: <UserOutlined />,
          items: [
            {
              key: '/offstreet/operators',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/offstreet/operators/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/offstreet/parkings/search',
              label: 'Parkings',
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/offstreet/sessions/search',
              label: 'Sessions',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
      ],
    },
    {
      key: '/payments',
      position: 'top',
      title: 'Finances',
      items: [
        {
          key: '/payments/contracts',
          label: 'Contrats',
          icon: <UserOutlined />,
          items: [
            {
              key: '/payments/contracts',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/payments/contracts/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
        {
          key: '/payments/transactions',
          label: 'Transactions',
          icon: <UserOutlined />,
          items: [
            {
              key: '/payments/transactions',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/payments/transactions/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
      ],
    },
    {
      key: '/adm',
      position: 'top',
      title: 'Admin',
      items: [
        {
          key: '/adm/translations',
          label: 'Traductions',
          icon: <UserOutlined />,
          items: [
            {
              key: '/adm/translations',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/adm/translations/search',
              label: 'Recherche',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
        {
          key: '/adm/hermes',
          label: 'Hermes',
          icon: <UserOutlined />,
          items: [
            {
              key: '/adm/hermes',
              label: "Vue d'ensemble",
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/mails/search',
              label: 'Mails',
              icon: <FullscreenExitOutlined />,
            },
            {
              key: '/sms/search',
              label: 'SMS',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
      ],
    },
    {
      key: '/other',
      position: 'bottom',
      title: '',
      items: [
        {
          key: '/settings',
          label: 'Setting',
          icon: <SettingOutlined />,
          items: [
            {
              key: '/profile',
              label: 'Profil',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
        {
          key: '/logout',
          label: 'Log Out',
          icon: <LoginOutlined />,
          items: [],
        },
      ],
    },
  ],
});
