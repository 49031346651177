import {
  RouterProvider,
  createBrowserRouter,
  createMemoryRouter,
  useLocation as abstractUseLocation,
  useNavigate as abstractUseNavigate,
  Link as abstractLink,
} from 'react-router-dom';

let currentMachine: any = null;
let masterMachine: any = null;
let allMachines: any = null;

export const dispatch = (event, payload) => {
  masterMachine.send(event, payload);
  allMachines.forEach((machine) => {
    machine.send(event, payload);
  });
};

export const dispatchOnIdle = (machine, event, payload) => {
  const onTransition = (state: any) => {
    if (state.matches('idle')) {
      dispatch(event, payload);

      machine.off(onTransition);
    }
  };

  machine.onTransition(onTransition);
};

export const useNavigate = abstractUseNavigate;
export const useLocation = abstractUseLocation;
export const Link = abstractLink;

export const Router = ({
  context = 'browser',
  sitemap,
  machine: master,
  store,
}) => {
  const createRouter: any =
    context === 'browser' ? createBrowserRouter : createMemoryRouter;

  const handleRoutes = (items, parentMachine = null) => {
    return items.map(({ Component, machine, children, ...rest }: any) => {
      const machineToUse = machine || parentMachine;

      const result = {
        ...rest,
        element: <Component machine={machineToUse} store={store} />,
        children: children
          ? [
              { index: true, Component, machine: machineToUse, store },
              ...handleRoutes(children, machineToUse),
            ]
          : null,
        loader: machineToUse
          ? () => {
              if (currentMachine?.id === machineToUse?.id) return false;

              if (currentMachine) {
                console.log('KILL', currentMachine.id);
                currentMachine.send('KILL', currentMachine);
              }

              console.log('WAKEUP', machineToUse?.id);
              machineToUse.send('WAKEUP');
              currentMachine = machineToUse;

              return true;
            }
          : null,
      };

      return result;
    });
  };

  masterMachine = master;
  allMachines = recursiveMachines(sitemap);

  const routes = handleRoutes(sitemap);
  const router = createRouter(routes);

  return <RouterProvider router={router} />;
};

const recursiveMachines = (items): Array<any> => {
  return items.reduce((acc, { machine, children }) => {
    const all = [...acc];
    if (machine) all.push(machine);
    if (children && children.length) all.push(...recursiveMachines(children));
    return all;
  }, []);
};
