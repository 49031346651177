import Field from '../../../components/field/field.component';
import Card3D from '../../../effects/card3D/card3D.effect';
import Button from '../../../components/button/button.component';
import { PagePropsType } from '../../../types/pages';
import './login.page.scss';
import { useEffect } from 'react';
import { appService } from '../../../app.machine';
import { useNavigate } from 'react-router-dom';
import { useSelector } from '@xstate/react';

export const LoginPage = ({ machine }: PagePropsType) => {
  const navigate = useNavigate();
  const error = useSelector(machine, ({ context }: any) => context.error);

  useEffect(() => {
    appService.send('SET_NAVIGATE_CALLBACK', {
      navigate,
    });
  }, []);

  return (
    <div className="login">
      <div className="login__present">
        <video
          muted
          autoPlay
          loop
          src="https://www.iemgroup.com/wp-content/uploads/2019/05/timelapse-home-IEM-v4-1.mp4"
        ></video>

        <div className="login__present__logo">
          <Card3D
            config={{
              options: {
                perspective: 2000,
                speed: 100,
                max: 20,
                scale: 1.1,
                transition: true,
                glare: true,
                'max-glare': 1,
              },
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.9 71.4">
              <path d="M0,0V71.4H54.9V0Z" fill="#ffffff75" />
              <g>
                <polygon points="30.7 42.8 36.3 42.8 39.5 56.2 42.7 42.8 48.3 42.8 48.3 60.4 44.8 60.4 44.8 45.7 44.7 45.7 41.4 60.4 37.6 60.4 34.3 45.7 34.2 45.7 34.2 60.4 30.7 60.4 30.7 42.8" />
                <polygon points="14.1 42.8 27.4 42.8 27.4 45.9 17.9 45.9 17.9 49.6 26.6 49.6 26.6 52.7 17.9 52.7 17.9 57.2 27.8 57.2 27.8 60.4 14.1 60.4 14.1 42.8" />
                <rect x="6.6" y="42.8" width="3.9" height="17.6" />
              </g>
            </svg>
          </Card3D>
        </div>
      </div>

      <form className="login__form">
        <div className="login__form__contain">
          <h1 className="login__form__contain__title">Se connecter</h1>

          <div className="login__form__contain__line">
            <Field
              handleEvent={(type: string, value: any) => {
                machine.send('UPDATE_USERNAME', { username: value });
              }}
              config={{
                input: { type: 'email' },
                label: { text: 'Email' },
              }}
            />
          </div>
          <div className="login__form__contain__line">
            <Field
              handleEvent={(type: string, value: any) => {
                machine.send('UPDATE_PASSWORD', { password: value });
              }}
              config={{
                input: { type: 'password' },
                label: { text: 'Mot de passe' },
              }}
            />
          </div>
          {error && <p>ERROR !!!</p>}
          <div className="login__form__contain__line">
            <Button
              config={{ type: 'primary', text: 'Se connecter' }}
              handleEvent={() => {
                console.log('SUBMIT');

                machine.send('SUBMIT_LOGIN');
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
