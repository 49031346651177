import { dispatchOnIdle, useNavigate } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { useEffect } from 'react';
import Table from '../../../components/table/table.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Widget from '../../../wraps/widget/widget.wrap';

export const SitesSearch: React.FC<PagePropsType> = ({ machine }) => {
  const sites = useSelector(machine, ({ context }: any) => context.sites);

  const navigate = useNavigate();

  const search = ({ limit, page }: { limit: number; page: number }) => {
    dispatchOnIdle(machine, 'SEARCH_SITES', {
      params: {
        limit: limit,
        page: page,
      },
    });
  };

  useEffect(() => {
    search({ limit: 20, page: 1 });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Col>
        <Widget config={{ title: 'Liste des sites' }}>
          <Table
            handleEvent={(type: string, value: any) => {
              switch (type) {
                case 'paging':
                  search(value);
                  break;
                case 'read':
                  navigate(`/onstreet/sites/${value._id}`);
                  break;
              }
            }}
            data={sites}
            config={{
              columns: [
                {
                  title: 'Client',
                  key: 'clientId',
                },
                {
                  title: 'Group',
                  key: 'groupId',
                },
                {
                  title: 'UPS',
                  key: 'upsId',
                },
                {
                  title: 'Terminal',
                  key: 'terminalId',
                },
              ],
              focus: true,
            }}
          />
        </Widget>
      </Col>
    </Page>
  );
};
