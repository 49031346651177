import { Pagination as PaginationAnt, Table as TableAnt } from 'antd';
import './table.component.scss';

interface Props {
  handleEvent: (type: string, value: any) => any;
  data: {
    list: any[];
    paging: { limit: number; count: number; current: number };
  };
  config: {
    columns: TableColumns[];
    yMax?: number | string;
    focus?: boolean;
  };
}

interface TableColumns {
  title: string;
  key: string;
}

const Table: React.FC<Props> = ({ handleEvent, config, data }) => {
  const list = data?.list ?? [];
  const paging = data?.paging ?? { limit: 20, current: 1, count: 1 };

  const listFormatted = list.map(({ _id, ...rest }) => {
    return { ...rest, _id, key: _id };
  });

  const columnsFormatted: any = config?.columns?.map(({ title, key }) => {
    return { title, key, dataIndex: key };
  });

  let options = {};

  options = {
    ...options,
    expandable: {
      expandedRowRender: (record: any) => {
        console.log('🚀 ~ file: table.component.tsx:42 ~ record:', record);

        return <>{record.expandable}</>;
      },
      rowExpandable: (record: any) => {
        console.log('🚀 ~ file: table.component.tsx:47 ~ record:', record);

        return record.expandable;
      },
    },
  };

  if (config?.focus)
    columnsFormatted.push({
      title: 'action',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => (
        <a onClick={() => handleEvent('read', record)}>Voir</a>
      ),
    });

  return (
    <div className="table">
      <div
        className={`table__contain ${list?.length === 0 ? 'table-empty' : ''}`}
      >
        <TableAnt
          style={{
            maxHeight: config?.yMax ?? 'initial',
            height: config?.yMax ?? 'initial',
          }}
          className={`${list?.length === 0 ? 'table-empty' : ''}`}
          size="middle"
          columns={columnsFormatted}
          dataSource={listFormatted}
          pagination={false}
          {...options}
        />
      </div>
      <div className="table__page">
        {paging && (
          <PaginationAnt
            showQuickJumper
            showSizeChanger
            onChange={(page, limit) => handleEvent('paging', { page, limit })}
            showTotal={(total) => `${total} lignes`}
            defaultCurrent={paging.current}
            current={paging.current}
            defaultPageSize={paging.limit}
            pageSize={paging.limit}
            total={paging.count}
            pageSizeOptions={[10, 20, 30, 40, 50]}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
