import './result.component.scss';
import { Result as ResultAnt } from 'antd';

interface Props {
  data: {
    status: 'success' | 'warning' | 'error';
    title?: string;
    subtitle?: string;
  };
}

const Result: React.FC<Props> = ({ data }) => {
  return (
    <div className="result component">
      <ResultAnt
        status={data.status}
        title={data?.title ?? undefined}
        subTitle={data?.subtitle ?? undefined}
      ></ResultAnt>
    </div>
  );
};

export default Result;
