import { gql } from '@apollo/client';

export const parkingsGql = gql`
  query parkings($limit: Int!, $page: Int!, $order: String) {
    parkings(limit: $limit, page: $page, order: $order) {
      list {
        _id
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
