import { Domain } from '@gimlite/domain';
import { GraphQLRequestType } from '../types/requests';

const bffClient: any = new Domain('http://localhost:3106/graphql');

export async function requestGQL({
  params,
  gql,
  render,
}: GraphQLRequestType): Promise<any> {
  const { definitions } = gql;
  const [
    {
      operation,
      name: { value },
    },
  ] = definitions as any[];

  return bffClient[operation](gql, {
    fetchPolicy: 'no-cache',
    variables: { ...params },
  })
  .then((res: any) => (render ? render(res[value]) : res[value]));
}
