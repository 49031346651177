import { assign, createMachine } from 'xstate';
import { interpret } from 'xstate';
import { appService } from '../../app.machine';
import { requestGQL } from '../../functions/request.function';
import loginGql from './gql/login.gql';

const initialContext = {
  username: undefined,
  password: undefined,
  token: undefined,
  error: false,
};

const machine = createMachine(
  {
    id: 'login',
    initial: 'off',
    predictableActionArguments: true,
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          UPDATE_USERNAME: { actions: 'updateUsername' },
          UPDATE_PASSWORD: { actions: 'updatePassword' },
          SUBMIT_LOGIN: { target: 'submit' },
          KILL: { target: 'off' },
        },
      },
      submit: {
        invoke: {
          src: 'authenticate',
          onDone: {
            actions: ['success', 'emitUser', 'reset'],
            target: 'idle',
          },
          onError: {
            actions: ['error', 'reset'],
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: { actions: assign(initialContext) },
    },
  },
  {
    actions: {
      updateUsername: assign({
        username: (_, { username }) => username,
        error: false,
      }),
      updatePassword: assign({
        password: (_, { password }) => password,
        error: false,
      }),
      success: assign({
        token: (_, { data }) => data.token,
        password: undefined,
      }),
      error: assign({ error: true }),
      reset: assign({
        username: undefined,
        password: undefined,
        token: undefined,
      }),
      emitUser: ({ username, token }) => {
        appService.send('LOGIN_USER', {
          username,
          token,
        });
      },
    },
    services: {
      authenticate: async ({ username, password }) => {
        return requestGQL({
          params: { username, password },
          gql: loginGql,
        });
      },
    },
  },
);

export const loginService = interpret(machine).start();
