import { ApiFilled } from '@ant-design/icons';
import { dispatchOnIdle } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Badge from '../../../components/badge/badge.component';
import DatePicker from '../../../components/datepicker/datepicker.component';
import Diagramme from '../../../components/diagramme/diagramme.component';
import Graph from '../../../components/graph/graph.component';
import List from '../../../components/list/list.component';
import Space from '../../../components/space/space.component';
import Tab from '../../../components/tab/tab.component';
import Table from '../../../components/table/table.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Row from '../../../wraps/row/row.wrap';
import Widget from '../../../wraps/widget/widget.wrap';
import {
  FullscreenExitOutlined,
  ClockCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import Timeline from '../../../components/timeline/timeline.component';
import luxon, { DateTime } from 'luxon';
import Statistic from '../../../components/statistic/statistic.component';
import Result from '../../../components/result/result.component';

export const SitesDetails: React.FC<PagePropsType> = ({ machine }) => {
  const { siteId } = useParams();

  useEffect(() => {
    if (siteId) {
      dispatchOnIdle(machine, 'SITE_SELECTED', { siteId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const site = useSelector(machine, ({ context }: any) => context.site);
  console.log('🚀 ~ file: sites.details.tsx:38 ~ site:', site);

  // console.log([
  //   ...site?.peripheral?.list?.map((element: any) => {
  //     const { kind, swvers } = element;

  //     return {
  //       title: kind,
  //       description: swvers,
  //       icon: <CloudDownloadOutlined />,
  //     };
  //   }),
  // ]);

  const [rubrique, setRubrique] = useState('global');

  if (!site) {
    return (
      <Page>
        <p>LOADING</p>
      </Page>
    );
  }

  return (
    <Page
      handleEvent={(type: any, value: any) => {
        switch (type) {
          case 'navigation':
            setRubrique(value);
            break;
        }
        console.log({ type, value });
      }}
      config={{
        nav: {
          selected: rubrique,
          items: [
            {
              label: 'Global',
              key: 'global',
              icon: <FullscreenExitOutlined />,
            },
            {
              label: 'Finance',
              key: 'finance',
              icon: <FullscreenExitOutlined />,
            },
            {
              label: 'Hardware',
              key: 'hardware',
              icon: <FullscreenExitOutlined />,
            },
          ],
        },
      }}
    >
      {rubrique === 'global' && (
        <Row>
          <Col>
            <Widget config={{ title: 'Etat du terminal' }}>
              <Result data={site.state} />
              <Space />
            </Widget>
            <Widget config={{ title: 'Historique des opérations' }}>
              <Timeline
                data={{
                  items: [
                    {
                      label: '2015-09-01',
                      children: 'Collecte CB',
                      color: 'green',
                    },
                    {
                      label: '2015-09-02 09:12:11',
                      children: 'Collecte de pièces',
                      color: 'green',
                    },
                    {
                      label: '2015-09-03 11:12:11',
                      children: 'Collecte de pièces',
                      color: 'green',
                    },
                    {
                      label: '2015-09-05 09: 12:11',
                      children: "Alarm d'energie",
                      color: 'red',
                    },
                    {
                      label: '2015-09-07 09:12:11',
                      children: "Passage d'un opérateur",
                    },
                    {
                      label: '2015-09-01',
                      children: 'Create a services',
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    },
                    {
                      color: 'red',
                      label: '2015-09-01 09:12:11',
                      children: 'Solve initial network problems',
                    },
                    {
                      children: 'Technical testing',
                    },
                    {
                      color: 'orange',
                      label: '2015-09-01 09:12:11',
                      children: 'Network problems being solved',
                    },
                    {
                      label: '2015-09-01',
                      children: 'Create a services',
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    },
                    {
                      color: 'red',
                      label: '2015-09-01 09:12:11',
                      children: 'Solve initial network problems',
                    },
                    {
                      children: 'Technical testing',
                    },
                    {
                      color: 'orange',
                      label: '2015-09-01 09:12:11',
                      children: 'Network problems being solved',
                    },
                    {
                      label: '2015-09-01',
                      children: 'Create a services',
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    },
                    {
                      color: 'red',
                      label: '2015-09-01 09:12:11',
                      children: 'Solve initial network problems',
                    },
                    {
                      children: 'Technical testing',
                    },
                    {
                      color: 'orange',
                      label: '2015-09-01 09:12:11',
                      children: 'Network problems being solved',
                    },
                    {
                      label: '2015-09-01',
                      children: 'Create a services',
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    },
                    {
                      color: 'red',
                      label: '2015-09-01 09:12:11',
                      children: 'Solve initial network problems',
                    },
                    {
                      children: 'Technical testing',
                    },
                    {
                      color: 'orange',
                      label: '2015-09-01 09:12:11',
                      children: 'Network problems being solved',
                    },
                    {
                      label: '2015-09-01',
                      children: 'Create a services',
                      dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                    },
                    {
                      color: 'red',
                      label: '2015-09-01 09:12:11',
                      children: 'Solve initial network problems',
                    },
                    {
                      children: 'Technical testing',
                    },
                    {
                      color: 'orange',
                      label: '2015-09-01 09:12:11',
                      children: 'Network problems being solved',
                    },
                  ],
                }}
                config={{
                  mode: 'left',
                  yMax: '60vh',
                }}
              />
            </Widget>
          </Col>
        </Row>
      )}

      {rubrique === 'finance' && (
        <Row>
          <Col config={{ flex: 2 }}>
            <Row>
              <Widget config={{ title: 'Collecte de pièces' }}>
                <Table
                  handleEvent={(type: string, value: any) => {}}
                  data={site.collects}
                  config={{
                    yMax: 350,
                    columns: [
                      {
                        title: 'Date',
                        key: 'logDate',
                      },
                      {
                        title: 'Montant total',
                        key: 'totalAmount',
                      },
                      {
                        title: 'Pièces rejeté',
                        key: 'rejectedCoins',
                      },
                      {
                        title: 'Pièces inhibées',
                        key: 'inhibitedCoins',
                      },
                    ],
                  }}
                />
              </Widget>
            </Row>
            <Row>
              <Widget config={{ title: 'Etat du coffre' }}>
                <Col>
                  <Tab
                    handleEvent={(type, value) => {
                      switch (type) {
                        case 'option':
                      }
                    }}
                    data={{
                      items: [
                        {
                          key: 'euro',
                          label: 'Euro',
                          children: (
                            <Col>
                              <Graph
                                data={{
                                  items: [
                                    {
                                      type: '2011',
                                      value: 10,
                                    },
                                    {
                                      type: '2012',
                                      value: 250,
                                    },
                                    {
                                      type: '2013',
                                      value: 180,
                                    },
                                    {
                                      type: '2014',
                                      value: 150,
                                    },
                                    {
                                      type: '2015',
                                      value: 100,
                                    },
                                    {
                                      type: '2016',
                                      value: 300,
                                    },
                                  ],
                                }}
                                config={{
                                  type: {
                                    field: 'type',
                                    alias: 'test1',
                                  },
                                  value: {
                                    field: 'value',
                                    alias: 'test2',
                                  },
                                  yMax: 350,
                                  mode: 'line.basic',
                                }}
                              />
                            </Col>
                          ),
                        },
                        {
                          key: 'chf',
                          label: 'Franc Suisse',
                          children: (
                            <Col>
                              <Graph
                                data={{
                                  items: [
                                    {
                                      type: '2011',
                                      value: 10,
                                    },
                                    {
                                      type: '2012',
                                      value: 250,
                                    },
                                    {
                                      type: '2013',
                                      value: 180,
                                    },
                                    {
                                      type: '2014',
                                      value: 150,
                                    },
                                    {
                                      type: '2015',
                                      value: 100,
                                    },
                                    {
                                      type: '2016',
                                      value: 910,
                                    },
                                  ],
                                }}
                                config={{
                                  type: {
                                    field: 'type',
                                    alias: 'test1',
                                  },
                                  value: {
                                    field: 'value',
                                    alias: 'test2',
                                  },
                                  yMax: 350,
                                  mode: 'line.basic',
                                }}
                              />
                            </Col>
                          ),
                        },
                      ],
                      selected: 'chf',
                    }}
                  />
                </Col>
              </Widget>
            </Row>
          </Col>
        </Row>
      )}

      {rubrique === 'hardware' && (
        <Row>
          <Col config={{ flex: 1.5 }}>
            <Widget config={{ title: 'Energie' }}>
              <Graph
                data={{
                  items: site?.energies?.list,
                }}
                config={{
                  yMax: 400,
                  type: {
                    field: 'date',
                    alias: 'test1',
                  },
                  value: {
                    field: 'value',
                    alias: 'test2',
                  },
                  category: 'category',
                  mode: 'line.basic',
                }}
              />
            </Widget>
            <Widget config={{ title: 'Alarmes' }}>
              <Table
                handleEvent={(type: string, value: any) => {}}
                data={site?.alarms}
                config={{
                  yMax: 350,
                  columns: [
                    {
                      title: 'Alarm',
                      key: 'alarm',
                    },

                    {
                      title: 'StartDate',
                      key: 'startDate',
                    },
                    {
                      title: 'StopDate',
                      key: 'stopDate',
                    },
                  ],
                }}
              />
            </Widget>
            <Widget config={{ title: 'Modem' }}>
              <Table
                handleEvent={(type: string, value: any) => {}}
                data={site?.modemSessions}
                config={{
                  yMax: 350,
                  columns: [
                    {
                      title: 'band',
                      key: 'band',
                    },
                    {
                      title: 'chan',
                      key: 'chan',
                    },
                    {
                      title: 'oper',
                      key: 'oper',
                    },
                    {
                      title: 'opn',
                      key: 'opn',
                    },
                    {
                      title: 'rat',
                      key: 'rat',
                    },
                    {
                      title: 'rssi',
                      key: 'rssi',
                    },
                    {
                      title: 'sinr',
                      key: 'sinr',
                    },
                    {
                      title: 'conn',
                      key: 'conn',
                    },
                    {
                      title: 'trx',
                      key: 'trx',
                    },
                  ],
                }}
              />
            </Widget>
            <Widget config={{ title: 'Service Card Event' }}>
              <Table
                handleEvent={(type: string, value: any) => {}}
                data={site?.serviceCardEvents}
                config={{
                  yMax: 350,
                  columns: [
                    {
                      title: 'Date',
                      key: 'logDate',
                    },
                    {
                      title: 'Uid',
                      key: 'UID',
                    },
                    {
                      title: 'Type',
                      key: 'Type',
                    },
                    {
                      title: 'Status',
                      key: 'Status',
                    },
                    {
                      title: 'Details',
                      key: 'Details',
                    },
                  ],
                }}
              />
            </Widget>
          </Col>
          <Col>
            <Widget config={{ title: 'Périphériques' }}>
              <List data={site?.peripherals} config={{ yMax: 400 }} />
            </Widget>
            <Widget config={{ title: 'Version Logiciels' }}>
              <List data={site?.versions} config={{ yMax: 400 }} />
            </Widget>
            <Widget config={{ title: 'Alarms courantes' }}>
              <List data={site.alarmsStatus} />
            </Widget>
          </Col>
        </Row>
      )}
    </Page>
  );
};
