import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from '@gimlite/router';
import { Outlet } from 'react-router-dom';
import Nav from '../../components/nav/nav.component';
import './main.wrap.scss';
import { dispatch } from '@gimlite/router';
import { menu } from '../../menu';
import { appService } from '../../app.machine';

interface Props {}

const Main: React.FC<Props> = () => {
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`,
    );
    document.documentElement.style.setProperty(
      '--vw',
      `${window.innerWidth * 0.01}`,
    );

    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`,
      );
      document.documentElement.style.setProperty(
        '--vw',
        `${window.innerWidth * 0.01}`,
      );
    });
  }, []);

  useEffect(() => {
    appService.send('SET_NAVIGATE_CALLBACK', {
      navigate,
    });

    const initialRedirectionTarget = {
      guest: '/login',
      user: location.pathname,
    };

    appService.send('SET_INITIAL_TARGET', {
      redirectionTarget: initialRedirectionTarget,
    });
  }, []);

  useEffect(() => {
    appService.onTransition((state: any) => {
      if (state.matches('ready')) {
        setReady(true);
      }
    });
  }, []);

  if (!ready) return <>LOADING</>;

  return (
    <main className="main">
      <div className="main__part">
        <Nav
          data={menu(location)}
          handleEvent={(type, key) => {
            if ('/logout' === key) {
              dispatch('LOGOUT', {});
            } else {
              navigate(key);
            }
          }}
        />
      </div>
      <div className="main__part">
        <Outlet />
      </div>
    </main>
  );
};

export default Main;
