import { Router } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { appService } from './app.machine';
import { guestPathToPage, userPathToPage } from './sitemap';

export const CustomRouter: React.FC<any> = () => {
  const user = useSelector(appService, ({ context }: any) => context.user);
  const store = useSelector(appService, ({ context }: any) => context);

  return (
    <Router
      context="browser"
      sitemap={user ? userPathToPage : guestPathToPage}
      store={store}
      machine={appService}
    />
  );
};
