import { gql } from '@apollo/client';

export const site = gql`
  query site($siteId: String!) {
    site(siteId: $siteId) {
      _id
      clientId
      groupId
      upsId
      terminalId
      energies {
        list {
          logDate
          u {
            bat
            sol
            alt
          }
          p {
            bat
            sol
            alt
            lpt
            core
            com
            misc
            eft
          }
        }
      }
      peripherals {
        terminalId
        mfg
        mdl
        sn
        present
        kind
        logDate
        hwvers
        swvers
        bootvers
        createdAt
      }
      alarms {
        list {
          _id
          key
          type
          domain
          occurence
          alarm
          startDate
          stopDate
        }
        paging {
          current
          count
          limit
        }
      }
      alarmsStatus {
        _id
        key
        type
        domain
        alarms
        createdAt
      }
      modemSessions {
        list {
          _id
          net {
            rat
            opn
            oper
            band
            chan
            rssi
            sinr
          }
          t {
            conn
            trx
          }
        }
        paging {
          current
          count
          limit
        }
      }
      serviceCardEvents {
        list {
          _id
          fragmentRef
          terminalId
          siteId
          logDate
          UID
          Type
          Status
          Details
          createdAt
        }
        paging {
          current
          count
          limit
        }
      }
      versions {
        _id
        terminalId
        name
        version
        logDate
        siteId
        createdAt
        updatedAt
      }
      collects {
        list {
          _id
          fragmentRef
          terminalId
          siteId
          logDate
          siteId
          rejectedCoins
          inhibitedCoins
          totalAmount
          createdAt
          coins
          sumPerCurrency
        }
        paging {
          current
          count
          limit
        }
      }
    }
  }
`;
