import { dispatch } from '@gimlite/router';
import { useSelector } from '@xstate/react';
import { Input } from 'antd';
import { useEffect, useState } from 'react';
import Table from '../../../components/table/table.component';
import { PagePropsType } from '../../../types/pages';
import Filter from '../../../wraps/filter/filter.wrap';
import Page from '../../../wraps/page/page.wrap';
import Widget from '../../../wraps/widget/widget.wrap';

type QueryParamsType = {
  consumer?: string;
};

type PagingParamsType = {
  page?: number;
  limit?: number;
};

export const ClientsSearch: React.FC<PagePropsType> = ({ machine }) => {
  const [queryInputParams, setQueryInputParams] = useState<QueryParamsType>();
  const [queryParams, setQueryParams] = useState<QueryParamsType>();
  const [pagingParams, setPagingParams] = useState<PagingParamsType>();

  const clients = useSelector(machine, ({ context }: any) => context.clients);

  useEffect(() => {
    if (queryParams && pagingParams) {
      if (!queryInputParams) {
        setQueryInputParams(queryParams);
      }

      dispatch('SEARCH_CLIENTS', {
        params: {
          ...queryParams,
          ...pagingParams,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, pagingParams]);

  useEffect(() => {
    if (clients && queryParams && pagingParams) {
      if (
        pagingParams.page !== clients.paging.current &&
        pagingParams.limit !== clients.paging.limit
      ) {
        setPagingParams({
          page: clients.paging.current,
          limit: clients.paging.limit,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);

  return (
    <Page>
      <Filter
        config={{ submit: true }}
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        pagingParams={pagingParams}
        setPagingParams={setPagingParams}
        handleEvent={(type) => {
          if ('submit' === type) {
            setQueryParams(queryInputParams);
          }
        }}
      >
        <Input
          type="text"
          value={queryInputParams?.consumer}
          onChange={(event) =>
            setQueryInputParams((prev) => ({
              ...prev,
              consumer: event.target.value,
            }))
          }
          style={{ width: 100 }}
        />
      </Filter>
      <Widget>
        <Table
          handleEvent={(type: string, value: any) => {
            switch (type) {
              case 'paging':
                setPagingParams(value);
                break;
              case 'read':
                console.log({ value });
                break;
            }
          }}
          data={clients}
          config={{
            columns: [
              {
                title: 'Name',
                key: 'slug',
              },
              {
                title: 'Siret',
                key: 'siret',
              },
            ],
          }}
        />
      </Widget>
    </Page>
  );
};
