import './menu.component.scss';
import { Menu as MenuAnt } from 'antd';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  handleEvent: (type: string, value: any) => any;
  data: {
    title?: string;
    items: any;
    selected: string;
  };
}

const Menu: React.FC<Props> = ({
  handleEvent,
  data: { items, selected, title = '' },
}) => {
  const uuid = uuidv4();

  const itemsFormatted = [
    {
      key: uuid,
      icon: null,
      label: title,
      type: 'group',
      children: [...items].map(({ key, icon, label, type }) => {
        return { key, icon, label, type };
      }),
    },
  ];

  return (
    <MenuAnt
      selectedKeys={[selected]}
      onSelect={({ key }) => handleEvent('navigation', key)}
      className="menu"
      items={itemsFormatted}
    />
  );
};

export default Menu;
