import {
  ApiFilled,
  ClockCircleOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import Badge from '../../../components/badge/badge.component';
import DatePicker from '../../../components/datepicker/datepicker.component';
import Diagramme from '../../../components/diagramme/diagramme.component';
import Graph from '../../../components/graph/graph.component';
import List from '../../../components/list/list.component';
import Statistic from '../../../components/statistic/statistic.component';
import Tab from '../../../components/tab/tab.component';
import Table from '../../../components/table/table.component';
import Timeline from '../../../components/timeline/timeline.component';
import { PagePropsType } from '../../../types/pages';
import Col from '../../../wraps/col/col.wrap';
import Page from '../../../wraps/page/page.wrap';
import Row from '../../../wraps/row/row.wrap';
import Widget from '../../../wraps/widget/widget.wrap';
import Space from '../../../components/space/space.component';

export const ClientsOverview: React.FC<PagePropsType> = ({ machine }) => {
  //! temporaire
  const [dataGrapgh, setDataGraph] = useState([]);
  const asyncFetch = () => {
    fetch(
      'https://gw.alipayobjects.com/os/bmw-prod/55424a73-7cb8-4f79-b60d-3ab627ac5698.json',
    )
      .then((response: any) => response.json())
      .then((json: any[]) =>
        json
          .filter(
            ({ category }) =>
              category === 'Liquid fuel' || category === 'Solid fuel',
          )
          .map(({ category, ...rest }) => {
            return {
              ...rest,
              category: category === 'Solid fuel' ? 'energy' : 'watt',
            };
          }),
      )
      .then((json: any) => {
        console.log('🚀 ~ file: clients.overview.tsx:44 ~ .then ~ json:', json);
        // console.log({ json });
        setDataGraph(json);
      })
      .catch((error) => {
        // console.log('fetch data failed', error);
      });
  };

  useEffect(() => {
    asyncFetch();
  }, []);

  return (
    <Page>
      <Col
        config={{
          flex: 2,
        }}
      >
        <Row>
          <Widget config={{ title: 'Liste des tickets' }}>
            <Table
              handleEvent={(type: string, value: any) => {}}
              data={{
                list: [
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                ],
                paging: { limit: 1, current: 1, count: 1 },
              }}
              config={{
                yMax: 350,
                columns: [
                  {
                    title: 'Date',
                    key: 'date',
                  },
                  {
                    title: 'Montant',
                    key: 'montant',
                  },
                  {
                    title: 'Plaque',
                    key: 'plaque',
                  },
                ],
              }}
            />
          </Widget>
        </Row>
        <Row>
          <Widget config={{ title: 'Graphique énergie' }}>
            <Graph
              data={{
                items: dataGrapgh,
              }}
              config={{
                yMax: 400,
                type: {
                  field: 'year',
                  alias: 'test1',
                },
                value: {
                  field: 'value',
                  alias: 'test2',
                },
                category: 'category',
                mode: 'line.basic',
              }}
            />
          </Widget>
        </Row>
        <Row>
          <Widget config={{ title: 'Liste des logs' }}>
            <Table
              handleEvent={(type: string, value: any) => {}}
              data={{
                list: [
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                ],
                paging: { limit: 1, current: 1, count: 1 },
              }}
              config={{
                yMax: 250,
                columns: [
                  {
                    title: 'Date',
                    key: 'date',
                  },
                  {
                    title: 'Montant',
                    key: 'montant',
                  },
                  {
                    title: 'Plaque',
                    key: 'plaque',
                  },
                ],
              }}
            />
          </Widget>
        </Row>
        <Row>
          <Widget config={{ title: 'Liste des alarms' }}>
            <Table
              handleEvent={(type: string, value: any) => {}}
              data={{
                list: [
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                ],
                paging: { limit: 1, current: 1, count: 1 },
              }}
              config={{
                yMax: 250,
                columns: [
                  {
                    title: 'Date',
                    key: 'date',
                  },
                  {
                    title: 'Montant',
                    key: 'montant',
                  },
                  {
                    title: 'Plaque',
                    key: 'plaque',
                  },
                ],
              }}
            />
          </Widget>
        </Row>
        <Row>
          <Widget config={{ title: 'Liste des connexions modem' }}>
            <Table
              handleEvent={(type: string, value: any) => {}}
              data={{
                list: [
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                  {
                    date: '11/05/2023 11:31:21',
                    montant: '1.2 CHF',
                    plaque: 'GE777BZ',
                  },
                  {
                    date: '11/05/2023 11:31:22',
                    montant: '1.2 EUR',
                    plaque: 'CD437BZ',
                  },
                ],
                paging: { limit: 1, current: 1, count: 1 },
              }}
              config={{
                yMax: 250,
                columns: [
                  {
                    title: 'Date',
                    key: 'date',
                  },
                  {
                    title: 'Montant',
                    key: 'montant',
                  },
                  {
                    title: 'Plaque',
                    key: 'plaque',
                  },
                ],
              }}
            />
          </Widget>
        </Row>
      </Col>
      <Col>
        <Widget config={{ title: 'Version des logiciels' }}>
          <List
            data={[
              {
                title: 'datacomm',
                description: 'v23.7.1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'rootfs',
                description:
                  'livio-v23.7.5-b91973c-BuiltBy(gitlab-runner@iem-build)',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'Kernel',
                description: '5.10.116-10_livio-B-2_dts-livio-B-10',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'TFA-Boot',
                description: 'v2.4-r1.0(release)_dts-livio-B-1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'UBoot',
                description: '2020.10-6_livio-B-3_dts-livio-B-2',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'datacomm',
                description: 'v23.7.1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'rootfs',
                description:
                  'livio-v23.7.5-b91973c-BuiltBy(gitlab-runner@iem-build)',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'Kernel',
                description: '5.10.116-10_livio-B-2_dts-livio-B-10',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'TFA-Boot',
                description: 'v2.4-r1.0(release)_dts-livio-B-1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'UBoot',
                description: '2020.10-6_livio-B-3_dts-livio-B-2',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'datacomm',
                description: 'v23.7.1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'rootfs',
                description:
                  'livio-v23.7.5-b91973c-BuiltBy(gitlab-runner@iem-build)',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'Kernel',
                description: '5.10.116-10_livio-B-2_dts-livio-B-10',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'TFA-Boot',
                description: 'v2.4-r1.0(release)_dts-livio-B-1',
                icon: <CloudDownloadOutlined />,
              },
              {
                title: 'UBoot',
                description: '2020.10-6_livio-B-3_dts-livio-B-2',
                icon: <CloudDownloadOutlined />,
              },
            ]}
            config={{
              yMax: 350,
            }}
          />
        </Widget>
        <Widget config={{ title: 'Etat des périphériques' }}>
          <List
            data={[
              {
                title: 'Carte réseau',
                icon: <ApiFilled />,
                badge: <Badge config={{ text: false, type: 'up' }} />,
              },
              {
                title: 'Imprimante',
                icon: <ApiFilled />,
                badge: <Badge config={{ text: false, type: 'up' }} />,
              },
              {
                title: 'Monnayeur',
                icon: <ApiFilled />,
                badge: <Badge config={{ text: false, type: 'down' }} />,
              },
              {
                title: 'Carte réseau',
                icon: <ApiFilled />,
                badge: <Badge config={{ text: false, type: 'warn' }} />,
              },
            ]}
          />
        </Widget>
        <Widget config={{ title: 'Contenu de la tirelire' }}>
          <Col>
            <Statistic
              data={{ value: 1210 }}
              config={{
                precision: 2,
                title: 'Total',
                suffix: '$',
              }}
            />
            <Space />
            <DatePicker
              handleEvent={(type, value) => {
                console.log({ type, value });
              }}
              config={{ selected: new Date(), mode: 'range' }}
            />
            <Space />
            <Tab
              handleEvent={(type, value) => {
                switch (type) {
                  case 'option':
                }
              }}
              data={{
                items: [
                  {
                    key: 'euro',
                    label: 'Euro',
                    children: (
                      <Col>
                        <Statistic
                          data={{ value: 300 }}
                          config={{
                            precision: 2,
                            suffix: '€',
                          }}
                        />
                        <Graph
                          data={{
                            items: [
                              {
                                type: '2011',
                                value: 10,
                              },
                              {
                                type: '2012',
                                value: 250,
                              },
                              {
                                type: '2013',
                                value: 180,
                              },
                              {
                                type: '2014',
                                value: 150,
                              },
                              {
                                type: '2015',
                                value: 100,
                              },
                              {
                                type: '2016',
                                value: 300,
                              },
                            ],
                          }}
                          config={{
                            type: {
                              field: 'type',
                              alias: 'test1',
                            },
                            value: {
                              field: 'value',
                              alias: 'test2',
                            },
                            yMax: 250,
                          }}
                        />
                      </Col>
                    ),
                  },
                  {
                    key: 'chf',
                    label: 'Franc Suisse',
                    children: (
                      <Col>
                        <Statistic
                          data={{ value: 910 }}
                          config={{
                            precision: 2,
                            suffix: 'CHF',
                          }}
                        />
                        <Graph
                          data={{
                            items: [
                              {
                                type: '2011',
                                value: 10,
                              },
                              {
                                type: '2012',
                                value: 250,
                              },
                              {
                                type: '2013',
                                value: 180,
                              },
                              {
                                type: '2014',
                                value: 150,
                              },
                              {
                                type: '2015',
                                value: 100,
                              },
                              {
                                type: '2016',
                                value: 300,
                              },
                            ],
                          }}
                          config={{
                            type: {
                              field: 'type',
                              alias: 'test1',
                            },
                            value: {
                              field: 'value',
                              alias: 'test2',
                            },
                            yMax: 250,
                          }}
                        />
                      </Col>
                    ),
                  },
                ],
                selected: 'chf',
              }}
            />
          </Col>
        </Widget>
        <Widget config={{ title: 'Evènement carte' }}>
          <Col>
            <Statistic
              data={{ value: 12462 }}
              config={{
                precision: 2,
                title: 'Total',
                suffix: '$',
              }}
            />
            <Space />
            <DatePicker
              handleEvent={(type, value) => {
                console.log({ type, value });
              }}
              config={{ selected: new Date(), mode: 'range' }}
            />
            <Space />
            <Diagramme
              data={{
                items: [
                  {
                    amount: 0,
                    hours: 1,
                  },
                  {
                    amount: 0,
                    hours: 2,
                  },
                  {
                    amount: 0,
                    hours: 3,
                  },
                  {
                    amount: 0,
                    hours: 4,
                  },
                  {
                    amount: 0,
                    hours: 5,
                  },
                  {
                    amount: 0,
                    hours: 6,
                  },
                  {
                    amount: 0,
                    hours: 7,
                  },
                  {
                    amount: 0,
                    hours: 8,
                  },
                  {
                    amount: 90,
                    hours: 9,
                  },
                  {
                    amount: 130,
                    hours: 10,
                  },
                  {
                    amount: 541,
                    hours: 11,
                  },
                  {
                    amount: 341,
                    hours: 12,
                  },
                  {
                    amount: 511,

                    hours: 12 + 1,
                  },
                  {
                    amount: 241,
                    hours: 12 + 2,
                  },
                  {
                    amount: 531,
                    hours: 12 + 3,
                  },
                  {
                    amount: 521,
                    hours: 12 + 4,
                  },
                  {
                    amount: 90,
                    hours: 12 + 5,
                  },
                  {
                    amount: 1,
                    hours: 12 + 6,
                  },
                  {
                    amount: 45,
                    hours: 12 + 7,
                  },
                  {
                    amount: 0,
                    hours: 12 + 8,
                  },
                  {
                    amount: 0,
                    hours: 12 + 9,
                  },
                  {
                    amount: 0,
                    hours: 12 + 10,
                  },
                  {
                    amount: 0,
                    hours: 12 + 11,
                  },
                  {
                    amount: 0,
                    hours: 12 + 12,
                  },
                ],
              }}
              config={{
                type: {
                  field: 'hours',
                  alias: 'test1',
                },
                value: {
                  field: 'amount',
                  alias: 'test2',
                },
                mode: 'column.basic.vertical',
                yMax: 300,
              }}
            />
          </Col>
        </Widget>
        <Widget config={{ title: 'Historique des opérations' }}>
          <Timeline
            data={{
              items: [
                {
                  label: '2015-09-01',
                  children: 'Collecte CB',
                  color: 'green',
                },
                {
                  label: '2015-09-02 09:12:11',
                  children: 'Collecte de pièces',
                  color: 'green',
                },
                {
                  label: '2015-09-03 11:12:11',
                  children: 'Collecte de pièces',
                  color: 'green',
                },
                {
                  label: '2015-09-05 09: 12:11',
                  children: "Alarm d'energie",
                  color: 'red',
                },
                {
                  label: '2015-09-07 09:12:11',
                  children: "Passage d'un opérateur",
                },
                {
                  label: '2015-09-01',
                  children: 'Create a services',
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                },
                {
                  color: 'red',
                  label: '2015-09-01 09:12:11',
                  children: 'Solve initial network problems',
                },
                {
                  children: 'Technical testing',
                },
                {
                  color: 'orange',
                  label: '2015-09-01 09:12:11',
                  children: 'Network problems being solved',
                },
                {
                  label: '2015-09-01',
                  children: 'Create a services',
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                },
                {
                  color: 'red',
                  label: '2015-09-01 09:12:11',
                  children: 'Solve initial network problems',
                },
                {
                  children: 'Technical testing',
                },
                {
                  color: 'orange',
                  label: '2015-09-01 09:12:11',
                  children: 'Network problems being solved',
                },
                {
                  label: '2015-09-01',
                  children: 'Create a services',
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                },
                {
                  color: 'red',
                  label: '2015-09-01 09:12:11',
                  children: 'Solve initial network problems',
                },
                {
                  children: 'Technical testing',
                },
                {
                  color: 'orange',
                  label: '2015-09-01 09:12:11',
                  children: 'Network problems being solved',
                },
                {
                  label: '2015-09-01',
                  children: 'Create a services',
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                },
                {
                  color: 'red',
                  label: '2015-09-01 09:12:11',
                  children: 'Solve initial network problems',
                },
                {
                  children: 'Technical testing',
                },
                {
                  color: 'orange',
                  label: '2015-09-01 09:12:11',
                  children: 'Network problems being solved',
                },
                {
                  label: '2015-09-01',
                  children: 'Create a services',
                  dot: <ClockCircleOutlined style={{ fontSize: '16px' }} />,
                },
                {
                  color: 'red',
                  label: '2015-09-01 09:12:11',
                  children: 'Solve initial network problems',
                },
                {
                  children: 'Technical testing',
                },
                {
                  color: 'orange',
                  label: '2015-09-01 09:12:11',
                  children: 'Network problems being solved',
                },
              ],
            }}
            config={{
              mode: 'left',
              yMax: 500,
            }}
          />
        </Widget>
      </Col>
    </Page>
  );
};
