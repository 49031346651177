import { gsap } from 'gsap';
import './nav.component.scss';
import { useState, useRef } from 'react';
import Menu from '../menu/menu.component';
import { DoubleLeftOutlined } from '@ant-design/icons';
import { useMatches } from 'react-router-dom';

interface MenuChild {
  key: string;
  label: string;
  icon: any;
}
interface MenuParent {
  key: string;
  label: string;
  icon: any;
  items: MenuChild[];
}

interface MenuGroup {
  key: string;
  position: string;
  title: string;
  items: MenuParent[];
}

interface Props {
  handleEvent: (type: string, value: any) => any;
  data: {
    selected: string;
    group: MenuGroup[];
  };
}

const unwindGroups = (groups: Array<any>, parent?: string): Array<any> => {
  return groups.reduce(
    (acc, { key, items }) => [
      ...acc,
      ...(parent ? [{ parent, key }] : []),
      ...(items ? unwindGroups(items, key) : []),
    ],
    [],
  );
};

const Nav: React.FC<Props> = ({ handleEvent, data: { selected, group } }) => {
  // Get sitemap selected element
  const matches = useMatches();
  const lastElement: any = matches.find(
    ({ pathname }) => pathname === selected,
  );

  // Get corresponding nav element key if specified
  const selectedNavKey =
    lastElement?.handle?.navKey ||
    // Remove last "/"
    '/' +
      lastElement?.pathname
        .split('/')
        .filter((c: string) => !!c)
        .join('/') ||
    '/';

  // Get second level of nav elements
  const parentsLevel = group.reduce(
    (acc: any, cur: any) => [...acc, ...cur.items],
    [],
  );

  // Flatten nav elements
  const flatGroups = unwindGroups(parentsLevel);
  const selectedElement = flatGroups.find(({ key }) => key === selectedNavKey);

  const [selectedMenuParent, setSelectedMenuParent] = useState(
    selectedElement?.parent || selectedNavKey,
  );
  const [selectedMenuChild, setSelectedMenuChild] = useState(selectedNavKey);

  const navRef = useRef(null);
  const menuRef = useRef(null);
  const toggleOpen = useRef(null);
  const toggleClose = useRef(null);

  const handleEventParent = (type: string, key: any) => {
    if (type === 'navigation') {
      setSelectedMenuParent(key);
      setSelectedMenuChild(key);
      handleEvent(type, key);
    }
  };

  const handleEventChild = (type: string, key: any) => {
    if (type === 'navigation') {
      setSelectedMenuChild(key);
      handleEvent(type, key);
    }
  };

  const navParentContain = (filter: string) => (
    <>
      {group
        .filter(({ position }) => position === filter)
        .map(({ key, title, items }) => (
          <Menu
            key={key}
            handleEvent={handleEventParent}
            data={{ items, selected: selectedMenuParent, title }}
          />
        ))}
    </>
  );

  const navChildContain = () => (
    <>
      {group.reduce((acc: any, { items }) => {
        const find = items.find(({ key }) => key === selectedMenuParent);

        if (find) {
          acc = (
            <Menu
              handleEvent={(type, key) => handleEventChild(type, key)}
              data={{
                items: find.items,
                selected: selectedMenuChild,
              }}
            />
          );
        }

        return acc;
      }, null)}
    </>
  );

  const toggle = (action: boolean) => {
    const timeline = gsap.timeline({
      defaults: {
        ease: 'power1.inOut',
      },
    });

    timeline.addLabel('menu');
    timeline.to(
      menuRef.current,
      {
        opacity: action ? 0 : 1,
        duration: 0.15,
      },

      'menu',
    );
    timeline.addLabel('nav', '<=0.1');
    timeline.to(
      navRef.current,
      {
        width: action ? '0px' : '200px',
        duration: 0.4,
      },
      'nav',
    );

    timeline.to(
      toggleOpen.current,
      {
        opacity: action ? 1 : 0,
        pointerEvents: action ? 'visible' : 'none',
        duration: 0.15,
      },
      'nav',
    );
  };

  return (
    <div className="nav">
      <nav className="nav__parent">
        <div className="nav__parent__logo">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.9 71.4">
            <polygon points="30.7 42.8 36.3 42.8 39.5 56.2 42.7 42.8 48.3 42.8 48.3 60.4 44.8 60.4 44.8 45.7 44.7 45.7 41.4 60.4 37.6 60.4 34.3 45.7 34.2 45.7 34.2 60.4 30.7 60.4 30.7 42.8" />
            <polygon points="14.1 42.8 27.4 42.8 27.4 45.9 17.9 45.9 17.9 49.6 26.6 49.6 26.6 52.7 17.9 52.7 17.9 57.2 27.8 57.2 27.8 60.4 14.1 60.4 14.1 42.8" />
            <rect x="6.6" y="42.8" width="3.9" height="17.6" />
          </svg>
        </div>

        <div className="nav__parent__contain">
          <div>{navParentContain('top')}</div>
          <div>{navParentContain('bottom')}</div>
        </div>
      </nav>

      <nav ref={navRef} className="nav__child">
        <div className="nav__child__contain">
          <div
            onClick={() => toggle(true)}
            ref={toggleClose}
            className="nav__child__contain__toggle"
          >
            <DoubleLeftOutlined className="nav__child__contain__toggle__icon" />
          </div>
          <div ref={menuRef} className="nav__child__contain__menu">
            {navChildContain()}
          </div>
        </div>
        <div
          ref={toggleOpen}
          onClick={() => toggle(false)}
          className="nav__child__open"
        >
          <div className="nav__child__open__line"></div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;
