import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Button from '../../components/button/button.component';

interface Props {
  handleEvent: (type: string, value: any) => any;
  children: any;
  queryParams: any;
  setQueryParams: any;
  pagingParams: any;
  setPagingParams: any;
  config?: {
    submit?: boolean;
    clear?: boolean;
  };
}

const Filter: React.FC<Props> = ({
  handleEvent,
  children,
  queryParams,
  setQueryParams,
  pagingParams,
  setPagingParams,
  config,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateSearchParams = () => {
    if (pagingParams && queryParams) {
      const { page, limit } = pagingParams;

      setSearchParams({
        ...queryParams,
        ...(page && page !== 1 && { page: page }),
        ...(page && limit !== 20 && { limit: limit }),
      } as any);
    }
  };

  const submitFilter = () => {
    handleEvent('submit', undefined);
  };

  useEffect(() => {
    updateSearchParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams, pagingParams]);

  const loadQueryParams = () => {
    let newQueryParams: any = {};
    let newPagingParams: any = {
      page: 1,
      limit: 20,
    };
    searchParams.forEach((value, key) => {
      if (['page', 'limit'].includes(key)) {
        newPagingParams[key] = +value;
      } else {
        newQueryParams[key] = value;
      }
    });

    setPagingParams(newPagingParams);
    setQueryParams(newQueryParams);
  };

  useEffect(() => {
    loadQueryParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <div className="filter">
      <div className="filter__contain">{children}</div>
      <div className="filter__button">
        {config?.submit && (
          <Button
            config={{ type: 'primary', text: 'Filtrer' }}
            handleEvent={(type) => {
              switch (type) {
                case 'click':
                  submitFilter();
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Filter;
