import './button.component.scss';
import { Button as ButtonAnt } from 'antd';
import React from 'react';

interface Props {
  handleEvent: (type: string, value: any) => any;
  config: {
    type?: any;
    text?: any;
  };
}

const Button: React.FC<Props> = ({ handleEvent, config }) => {
  return (
    <ButtonAnt
      className="button"
      onClick={() => handleEvent('click', true)}
      type={config?.type}
    >
      {config?.text}
    </ButtonAnt>
  );
};

export default Button;
