import './col.wrap.scss';

interface Props {
  children: any;
  config?: {
    flex?: number;
  };
}

const Col: React.FC<Props> = ({ children, config }) => {
  return (
    <div
      style={{
        flex: config?.flex || 1,
      }}
      className="col"
    >
      {children}
    </div>
  );
};

export default Col;
