import { assign, createMachine, interpret } from 'xstate';

const initialContext = {
  error: undefined,
};

const machine = createMachine(
  {
    predictableActionArguments: true,
    id: 'hermes',
    initial: 'off',
    context: initialContext,
    states: {
      off: {
        on: {
          WAKEUP: {
            target: 'idle',
          },
        },
      },
      idle: {
        on: {
          KILL: {
            target: 'off',
          },
        },
      },

      failure: {
        on: {
          RETRY: {
            actions: assign({ error: undefined }),
            target: 'idle',
          },
        },
      },
    },
    on: {
      LOGOUT: {
        actions: assign(initialContext),
      },
    },
  },
  {
    services: {},
    guards: {
      ready: () => true,
    },
  },
);

export const hermesService = interpret(machine).start();
