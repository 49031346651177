import { clientsService } from './pages/clients/clients.machine';
import { ClientsOverview } from './pages/clients/view/clients.overview';
import { ClientsSearch } from './pages/clients/view/clients.search';
import { contractsService } from './pages/contracts/contracts.machine';
import { ContractsOverview } from './pages/contracts/view/contracts.overview';
import { ContractsSearch } from './pages/contracts/view/contracts.search';
import { hermesService } from './pages/hermes/hermes.machine';
import { HermesOverview } from './pages/hermes/view/hermes.overview';
import { HomePage } from './pages/home';
import { homeService } from './pages/home/home.machine';
import { loginService } from './pages/login/login.machine';
import { LoginPage } from './pages/login/view/login.page';
import { mailsService } from './pages/mails/mails.machine';
import { MailsSearch } from './pages/mails/view/mails.search';
import { operatorsService } from './pages/operators/operators.machine';
import { OperatorsDetails } from './pages/operators/view/operators.id';
import { OperatorsOverview } from './pages/operators/view/operators.overview';
import { OperatorsSearch } from './pages/operators/view/operators.search';
import { parkingRightsService } from './pages/parking-rights/parking-rights.machine';
import { ParkingRightsDetails } from './pages/parking-rights/view/parking-rights.details';
import { ParkingRightsList } from './pages/parking-rights/view/parking-rights.list';
import { ParkingRightsOverview } from './pages/parking-rights/view/parking-rights.overview';
import { parkingsService } from './pages/parkings/parkings.machine';
import { ParkingsOverview } from './pages/parkings/view/parkings.overview';
import { ParkingsSearch } from './pages/parkings/view/parkings.search';
import { sessionsService } from './pages/sessions/sessions.machine';
import { SessionsOverview } from './pages/sessions/view/sessions.overview';
import { SessionsSearch } from './pages/sessions/view/sessions.search';
import { sitesMachine } from './pages/sites/sites.machine';
import { SitesDetails } from './pages/sites/view/sites.details';
import { SitesSearch } from './pages/sites/view/sites.search';
import { smsService } from './pages/sms/sms.machine';
import { SmsSearch } from './pages/sms/view/sms.search';
import { transactionsService } from './pages/transactions/transactions.machine';
import { TransactionsOverview } from './pages/transactions/view/transactions.overview';
import { TransactionsSearch } from './pages/transactions/view/transactions.search';
import { translationsService } from './pages/translations/translations.machine';
import { TranslationsOverview } from './pages/translations/view/translations.overview';
import { TranslationsSearch } from './pages/translations/view/translations.search';
import { PageType } from './types/sitemap';
import Main from './wraps/main/main.wrap';

export const guestPathToPage: PageType[] = [
  {
    path: '/',
    Component: LoginPage,
    machine: loginService,
    children: [
      {
        path: '/login',
        Component: LoginPage,
      },
    ],
  },
];

export const userPathToPage: PageType[] = [
  {
    path: '/',
    Component: Main,
    children: [
      { path: '/', Component: HomePage, machine: homeService },
      // ONSTREET
      {
        path: '/onstreet/parking-rights',
        Component: ParkingRightsOverview,
        machine: parkingRightsService,
      },
      {
        path: '/onstreet/parking-rights/search',
        Component: ParkingRightsList,
        machine: parkingRightsService,
      },
      {
        path: '/onstreet/parking-rights/:parkingRightId',
        Component: ParkingRightsDetails,
        machine: parkingRightsService,
        handle: { navKey: '/onstreet/parking-rights/search' },
      },
      {
        path: '/onstreet/clients',
        Component: ClientsOverview,
        machine: clientsService,
      },
      {
        path: '/onstreet/clients/search',
        Component: ClientsSearch,
        machine: clientsService,
      },
      {
        path: '/onstreet/sites/search',
        Component: SitesSearch,
        machine: sitesMachine,
      },
      {
        path: '/onstreet/sites/:siteId',
        Component: SitesDetails,
        machine: sitesMachine,
        handle: { navKey: '/onstreet/sites/search' },
      },
      // OFFSTREET
      {
        path: '/offstreet/operators',
        Component: OperatorsOverview,
        machine: operatorsService,
      },
      {
        path: '/offstreet/operators/search',
        Component: OperatorsSearch,
        machine: operatorsService,
      },
      {
        path: '/offstreet/operators/:operatorId',
        Component: OperatorsDetails,
        machine: operatorsService,
        handle: { navKey: '/offstreet/operators/search' },
      },
      {
        path: '/offstreet/parkings',
        Component: ParkingsOverview,
        machine: parkingsService,
      },
      {
        path: '/offstreet/parkings/search',
        Component: ParkingsSearch,
        machine: parkingsService,
      },
      {
        path: '/offstreet/sessions',
        Component: SessionsOverview,
        machine: sessionsService,
      },
      {
        path: '/offstreet/sessions/search',
        Component: SessionsSearch,
        machine: sessionsService,
      },
      // PAYMENTS
      {
        path: '/payments/contracts',
        Component: ContractsOverview,
        machine: contractsService,
      },
      {
        path: '/payments/contracts/search',
        Component: ContractsSearch,
        machine: contractsService,
      },
      {
        path: '/payments/transactions',
        Component: TransactionsOverview,
        machine: transactionsService,
      },
      {
        path: '/payments/transactions/search',
        Component: TransactionsSearch,
        machine: transactionsService,
      },
      // ADMIN
      {
        path: '/adm/translations',
        Component: TranslationsOverview,
        machine: translationsService,
      },
      {
        path: '/adm/translations/search',
        Component: TranslationsSearch,
        machine: translationsService,
      },
      {
        path: '/mails/search',
        Component: MailsSearch,
        machine: mailsService,
      },
      {
        path: '/sms/search',
        Component: SmsSearch,
        machine: smsService,
      },
      {
        path: '/adm/hermes',
        Component: HermesOverview,
        machine: hermesService,
      },
    ],
  },
];
