export const sessionsRender = ({ list, paging }) => {
  return {
    list: list.map(({ _id, ...rest }) => {
      return {
        ...rest,
        key: _id,
      };
    }),
    paging,
  };
};
