import './timeline.component.scss';
import { Timeline as TimelineAnt } from 'antd';

import React from 'react';

interface Item {
  label?: string;
  children: string;
  dot?: any;
  color?: string;
}

interface Props {
  // handleEvent: (type: string, value: any) => any;
  config?: {
    mode?: 'left' | 'right' | 'alternate';
    yMax?: number | string;
  };
  data: {
    items: Item[];
  };
}

const Timeline: React.FC<Props> = ({ config, data: { items } }) => {
  return (
    <div style={{ maxHeight: config?.yMax ?? 'initial' }} className="timeline">
      <TimelineAnt
        pending="Load..."
        mode={config?.mode ?? 'left'}
        items={items}
      />
    </div>
  );
};

export default Timeline;
