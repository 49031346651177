import { useEffect, useRef, useState } from 'react';
import './page.wrap.scss';
interface NavItem {
  icon: any;
  key: string;
  label: string;
}
interface Props {
  handleEvent?: (type: string, value: any) => any;
  children: any;
  config?: {
    nav?: {
      selected: string;
      items: NavItem[];
    };
  };
}

const Page: React.FC<Props> = ({ handleEvent, children, config }) => {
  const [selectedNav, setSelectedNav] = useState(config?.nav?.selected);

  // const pageHTML = useRef(null);

  // const tableDimensionFn = (tableHTML: any) => {
  //   const pageHTMLCurrent: any = pageHTML?.current;
  //   const tableHead = tableHTML.querySelector('thead');
  //   const tableContain = tableHTML.querySelector('.table__contain');
  //   const tablePagination = tableHTML.querySelector('.table__page');
  //   const tablePlaceholder = tableHTML.querySelector('.ant-table-placeholder');
  //   const tablePlaceholderInner = tableHTML.querySelector(
  //     '.ant-table-placeholder .ant-table-cell',
  //   );
  //   const pageHeader = pageHTMLCurrent.querySelector('.page__header');

  //   let tableHeadHeight = 0;
  //   let pageHeaderHeight = 0;
  //   let pagePaginationHeight = 0;

  //   if (tableHead) tableHeadHeight = tableHead.getBoundingClientRect().height;
  //   if (pageHeader)
  //     pageHeaderHeight = pageHeader.getBoundingClientRect().height;
  //   if (tablePagination)
  //     pagePaginationHeight = tablePagination.getBoundingClientRect().height;

  //   const spaceRest =
  //     window.innerHeight -
  //     pageHeaderHeight -
  //     tableHeadHeight -
  //     pagePaginationHeight;

  //   if (tableContain) tableContain.style.height = spaceRest + 'px';
  //   if (tablePlaceholder)
  //     tablePlaceholder.style.height = spaceRest - pagePaginationHeight + 'px';
  //   if (tablePlaceholderInner)
  //     tablePlaceholderInner.style.height =
  //       spaceRest - pagePaginationHeight + 'px';
  // };

  // useEffect(() => {
  //   const pageHTMLCurrent: any = pageHTML?.current;
  //   let resizeTabelDimension: any;

  //   if (pageHTMLCurrent) {
  //     const findWidget = pageHTMLCurrent.querySelectorAll('.widget');

  //     if (findWidget.length === 1) {
  //       const [widget] = findWidget;
  //       const widgetTitle = widget.querySelector('.widget__title');
  //       const table = widget.querySelector('.table');
  //       widget.style.backgroundColor = 'transparent';
  //       widget.style.boxShadow = 'none';
  //       widget.style.padding = '0px';

  //       if (widgetTitle) widgetTitle.style.display = 'none';

  //       if (table) {
  //         resizeTabelDimension = () => tableDimensionFn(table);
  //         resizeTabelDimension();
  //         window.addEventListener('resize', resizeTabelDimension);
  //       }
  //     }
  //   }

  //   return () => {
  //     window.removeEventListener('resize', resizeTabelDimension);
  //   };
  // }, []);

  return (
    <div className="page">
      <div className="page__header">
        <div className="page__header__info">
          <h2 className="page__header__info__name">Dashboard</h2>
          <span className="page__header__info__job">...</span>
        </div>
        <div className="page__header__more"></div>
      </div>
      {config?.nav && (
        <div className="page__nav">
          <div className="page__nav__contain">
            {config?.nav?.items.map(({ key, icon, label }) => {
              return (
                <div
                  key={key}
                  onClick={() => {
                    setSelectedNav(key);
                    return handleEvent
                      ? handleEvent('navigation', key)
                      : undefined;
                  }}
                  className={`${
                    selectedNav === key ? 'selected' : ''
                  } page__nav__contain__item`}
                >
                  {icon}
                  <span className="page__nav__contain__item__text">
                    {label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <div className="page__contain">{children}</div>
    </div>
  );
};

export default Page;
