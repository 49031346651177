import './list.component.scss';
import React, { useRef } from 'react';
import { Avatar, List as ListAnt } from 'antd';
import { DownOutlined } from '@ant-design/icons';

interface Item {
  title?: string;
  description?: string;
  icon?: any;
  badge?: any;
}

interface Props {
  data: Item[];
  config?: { yMax?: number | string };
}

const List: React.FC<Props> = ({ data, config }) => {
  const dataFormatted = data ?? [];

  const listHTML: any = useRef(null);

  const expandable = (e: any) => {
    const currentExpandleContainerHTML =
      e.target.parentElement.parentElement.querySelector(
        '.list__contain__line__ex-container',
      );

    const currentExpandleIconHTML =
      e.target.parentElement.parentElement.querySelector(
        '.list__contain__line__ex-icon',
      );

    const allExpandableContainerHTML = listHTML.current.querySelectorAll(
      '.list__contain__line__ex-container',
    );

    const allExpandableIconHTML = listHTML.current.querySelectorAll(
      '.list__contain__line__ex-icon',
    );

    allExpandableContainerHTML.forEach((element: any) => {
      element.style.maxHeight = '0px';
    });

    allExpandableIconHTML.forEach((element: any) => {
      element.style.transform = 'rotate(-90deg)';
    });

    currentExpandleContainerHTML.style.maxHeight = '100%';
    currentExpandleIconHTML.style.transform = 'rotate(0deg)';
  };

  return (
    <div ref={listHTML} className="list">
      <div
        className="list__contain"
        style={{ maxHeight: config?.yMax ?? 'initial' }}
      >
        <ListAnt
          itemLayout="vertical"
          dataSource={dataFormatted}
          renderItem={(item, index) => (
            <div className="list__contain__line">
              <ListAnt.Item>
                <div
                  className="list__contain__line__ex-icon"
                  onClick={(e) => expandable(e)}
                >
                  <DownOutlined style={{ pointerEvents: 'none' }} />
                </div>
                <ListAnt.Item.Meta
                  avatar={item?.icon}
                  title={item?.title}
                  description={item?.description}
                />
                <span>{item?.badge}</span>
              </ListAnt.Item>
              <div className="list__contain__line__ex-container">
                <div className="list__contain__line__ex-container__description">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Nostrum eveniet a ipsum quam hic cumque explicabo, ad eligendi
                  eius id, incidunt nemo, architecto saepe adipisci distinctio
                  corporis? Tempore, enim dolores!
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default List;
